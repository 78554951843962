import { gql } from '@apollo/client'

const EVENT_LISTINGS_FRAGMENT = gql`
  fragment eventListingsFields on Event {
    id
    date
    startTime
    endTime
    title
    contentUrl
    flyerFront
    isTicketed
    interestedCount
    isSaved
    isInterested
    queueItEnabled
    newEventForm
    images {
      id
      filename
      alt
      type
      crop
    }
    pick {
      id
      blurb
    }
    venue {
      id
      name
      contentUrl
      live
    }
    promoters {
      id
    }
    artists {
      id
      name
    }
    tickets(queryType: AVAILABLE) {
      validType
      onSaleFrom
      onSaleUntil
    }
  }
`

export default EVENT_LISTINGS_FRAGMENT
