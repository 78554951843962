import {
  SubSection,
  SlashHeader,
  Alignment,
} from '@resident-advisor/design-system'
import { ThemeProvider } from 'styled-components'
import HorizontalList from 'components/generic/horizontal-list'
import { EventUpcomingCard } from 'components/events/cards/upcoming'
import { dark } from 'themes'
import Tracking, { TRACKING_EVENT } from 'scripts/tracking'
import { useEventListingsFiltersContext } from 'components/events/pages/events-home/event-listings/event-listings-filter-context'
import EventDto from 'interfaces/gql/EventDto'
import testIds from 'enums/testIds'
import axios from 'axios'
import { AreasFilterValue } from 'components/events/pages/events-home/event-listings/event-listings-filter-context/filter-values'
import useKevelTracking from 'hooks/useKevelTracking/useKevelTracking'

const PopularEventsSubSectionMarkup = ({
  data,
  title,
  SubmitEventButton,
  ...props
}: PopularEventsSubSectionMarkupProps) => {
  const { area } = useEventListingsFiltersContext()
  useKevelTracking(data)

  return (
    <ThemeProvider theme={dark}>
      <SubSection variant={SubSection.variant.secondary} {...props}>
        <Alignment justifyContent="space-between">
          <SlashHeader>{title}</SlashHeader>
          {SubmitEventButton && (
            <div data-testid={testIds.submitButton}>
              <SubmitEventButton />
            </div>
          )}
        </Alignment>

        <HorizontalList
          Card={EventUpcomingCard}
          mCols={4}
          items={data.map((item: EventDto, index: number) => ({
            ...item,
            withFlyer: true,
            bump: item.bump,
            onClick: () =>
              handleClick(
                item.id,
                item.title,
                area,
                index + 1,
                item?.bump?.clickUrl
              ),
          }))}
        />
      </SubSection>
    </ThemeProvider>
  )
}

const handleClick = (
  id: number | string,
  title: string,
  area: AreasFilterValue,
  position: number,
  bumpClickUrl?: string
) => {
  Tracking.trackMixpanel(TRACKING_EVENT.popularCarouselEventClicked, {
    'Event ID': id,
    'Event Title': title,
    'Events Region Id': area?.area?.id?.toString(),
    'Events Region Name': area?.areaName,
    'Position In Event Listing': position,
    'Ordering Used': 'New',
  })

  if (bumpClickUrl) {
    Tracking.trackMixpanel(TRACKING_EVENT.clickedPromotedEventListing, {
      'Event ID': id,
      'Listing Type': 'Popular',
    })
    axios.get(bumpClickUrl)
  }
}

type PopularEventsSubSectionMarkupProps = {
  data: EventDto[]
  title?: string
  SubmitEventButton?: () => JSX.Element
}

export default PopularEventsSubSectionMarkup
