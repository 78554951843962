import { gql } from '@apollo/client'
import EVENT_LISTINGS_FRAGMENT from 'components/events/pages/events-home/event-listings/queries/EventListingsFragment'

const GET_POPULAR_EVENTS = gql`
  query GET_POPULAR_EVENTS(
    $filters: FilterInputDtoInput
    $pageSize: Int
    $sort: SortInputDtoInput
    $includeBumps: Boolean!
    $areaId: ID
    $dateRange: DateRangeInput
    $type: ZoneType
  ) {
    eventListings(
      filters: $filters
      pageSize: $pageSize
      page: 1
      sort: $sort
    ) {
      data {
        id
        listingDate
        event {
          id
          title
          interestedCount
          isSaved
          isInterested
          date
          contentUrl
          flyerFront
          queueItEnabled
          newEventForm
          images {
            id
            filename
            alt
            type
            crop
          }
          venue {
            id
            name
            contentUrl
            live
          }
        }
      }
    }
    bumps(areaId: $areaId, dateRange: $dateRange, type: $type)
      @include(if: $includeBumps) {
      bumpDecision {
        id
        eventId
        clickUrl
        impressionUrl
        event {
          ...eventListingsFields
          artists {
            id
            name
          }
        }
      }
    }
  }
  ${EVENT_LISTINGS_FRAGMENT}
`

export default GET_POPULAR_EVENTS
