import PropTypes from 'prop-types'
import {
  Alignment,
  Box,
  LocationIcon,
  variants,
} from '@resident-advisor/design-system'
import InterestedCount from 'components/generic/interested-count/InterestedCount'
import Link from 'components/generic/link'
import Heading from 'components/generic/heading'
import Date from 'components/generic/date'
import Flag from 'components/shared/flag'
import MetaText from 'components/generic/meta-text'
import ResponsiveImage from 'components/generic/responsive-image'
import MetaLink from 'components/generic/meta-link'
import ASPECT_RATIO, { formatAspectRatio } from 'lib/aspectRatio'
import IMAGE_TYPE from 'enums/image-type'
import getEventImage from 'lib/getEventImage'
import { generateUrlForAreaAndCountry, getAreaAndCountry } from 'lib/utils'
import unescape from 'lodash/unescape'
import { eventsByAreaRoute, eventRoute } from 'lib/routes'
import { useFeatureSwitch } from 'context/FeatureSwitchesContext'
import featureSwitches from 'enums/feature-switches'
import SavedEventsButton, {
  EventSavedSourceType,
  SavedEventsButtonType,
} from 'components/user/pages/saved-events/saved-events-button/SavedEventsButton'

const EventUpcomingWithLocationCard = ({
  title,
  date,
  venue,
  contentUrl,
  flyerFront,
  queueItEnabled,
  withFlyer,
  area,
  newEventForm,
  images,
  isSaved,
  isInterested,
  id,
  interestedCount,
  artists,
  promoters,
  tickets,
  isTicketed,
}) => {
  const enableSavedEvents = useFeatureSwitch(featureSwitches.enableSavedEvents)

  return (
    <Alignment flexDirection="column">
      {withFlyer && (
        <Box mb={{ s: 2, m: 3 }}>
          <Link
            variant={variants.text.heading.m}
            {...eventRoute(contentUrl, queueItEnabled)}
          >
            <ResponsiveImage
              url={getEventImage(
                { flyerFront, images, newEventForm },
                IMAGE_TYPE.flyerFront
              )}
              aspect={ASPECT_RATIO['ISO-216']}
              alt={`'${title}' flyer image`}
              sizes={{ m: '25vw' }}
              properties={{
                fit: 'crop',
                ar: formatAspectRatio(ASPECT_RATIO['ISO-216']),
              }}
            />
          </Link>
        </Box>
      )}
      <Alignment flexDirection="row" justifyContent="space-between">
        <Date color="secondary" date={date} variant={variants.text.heading.s} />
        {enableSavedEvents && (
          <SavedEventsButton
            trackingData={{
              venue,
              artists,
              promoters,
              tickets,
              isTicketed,
              source: EventSavedSourceType.PopularSection,
            }}
            eventId={id}
            isInterested={isInterested}
            interestedCount={interestedCount}
            isSaved={isSaved}
            type={SavedEventsButtonType.EventCardIcon}
          />
        )}
      </Alignment>
      <Heading>
        <Link
          variant={variants.text.heading.m}
          {...eventRoute(contentUrl, queueItEnabled)}
        >
          {unescape(title)}
        </Link>
      </Heading>
      <Box mt={2}>
        <Alignment mb={1}>
          <Flag size={18} px="3px" urlCode={area.country.urlCode} />
          <Link
            my={1}
            variant={variants.text.label}
            {...eventsByAreaRoute(
              generateUrlForAreaAndCountry('/events', area)
            )}
          >
            {getAreaAndCountry(area)}
          </Link>
        </Alignment>
        {venue?.live ? (
          <MetaLink
            Icon={LocationIcon}
            text={venue.name}
            href={venue.contentUrl}
          />
        ) : (
          <MetaText Icon={LocationIcon} text={venue.name} />
        )}
        <InterestedCount count={interestedCount} />
      </Box>
    </Alignment>
  )
}

EventUpcomingWithLocationCard.propTypes = {
  title: PropTypes.string.isRequired,
  venue: PropTypes.object.isRequired,
  interestedCount: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  contentUrl: PropTypes.string.isRequired,
  area: PropTypes.object.isRequired,
  flyerFront: PropTypes.string,
  queueItEnabled: PropTypes.bool,
  withFlyer: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      filename: PropTypes.string.isRequired,
      alt: PropTypes.string,
      type: PropTypes.oneOf(Object.values(IMAGE_TYPE)),
      crop: PropTypes.object,
    })
  ),
  newEventForm: PropTypes.bool,
  isSaved: PropTypes.bool,
  isInterested: PropTypes.bool,
  id: PropTypes.string,
  artists: PropTypes.array,
  promoters: PropTypes.array,
  tickets: PropTypes.array,
  isTicketed: PropTypes.bool,
}

export default EventUpcomingWithLocationCard
